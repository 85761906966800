import React, { useEffect, useState } from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Header from "../../sections/header"
import Footer from "../../sections/footer"
import ScoringForm from "../../components/scoring-form"
import "./styles.css"
import { getQueryParams, ucFirst, useTranslation } from "../../utils"
import { getScore } from "../../api/scoring"
import ScoringLoader from "../../components/scoring-loader"
import { toast } from "react-toastify"
import ScoringTable from "../../sections/scoring-table"
import ScoringBanner from "../../components/scoring-banner"
import { useAuthCheck } from "../../hooks/useAuthCheck"
import ScoringError from "../../sections/scoring-table/error"

const ScoringPage = ({ location }) => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [dataLoaded, setDataLoaded] = useState(false)
  const { authed } = useAuthCheck()
  const { locale } = useTranslation()
  useEffect(() => {
    const fetchData = async () => {
      const url = getQueryParams(location.search)["url"]
      const email = getQueryParams(location.search)["email"]
      if (url && email) {
        setDataLoaded(false)
        setLoading(true)
        setData(null)
        const { data } = await getScore(url, email, locale)
        setDataLoaded(true)
        setTimeout(() => {
          setData(data)
          if (data && data.object && data.object.url) {
            document.title = ucFirst(
              data.object.url +
                " Security Report. Cyber Risk Assessment Report."
            )
          }
          if (data && data.errorText) {
            toast(data.errorText)
          }

          setLoading(false)
        }, 1000)
      }
    }
    fetchData()
  }, [location])

  return (
    <Layout>
      <SEO title="Security Report. Cyber Risk Assessment Report." />
      <Header />

      <div className="scoring-page">
        <div className="container">
          {!loading ? (
            <ScoringForm location={location} />
          ) : (
            <ScoringLoader isFinish={dataLoaded} />
          )}

          {data && data.errorText ? (
            <ScoringError text={data.errorText} />
          ) : (
            data &&
            data.object && (
              <>
                <ScoringTable data={data} authed={authed} />
                {!authed && <ScoringBanner />}
              </>
            )
          )}
        </div>
      </div>

      <Footer />
    </Layout>
  )
}

export default ScoringPage
