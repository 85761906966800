import React, { useState } from "react"
import "./styles.css"
import classNames from "classnames"
import WarningIcon from "../../images/warning.svg"
import ScoringError from "./error"
import ReactModal from "react-modal"
import { customStyles } from "../../styles/modal"
import Methodology from "./methodology"
import { useTranslation } from "../../utils"

ReactModal.setAppElement("#___gatsby")

const getScoreGrade = score => {
  if (score >= 100) {
    return "A+"
  } else if (score > 90) {
    return "A"
  } else if (score > 80) {
    return "A-"
  } else if (score > 70) {
    return "B+"
  } else if (score > 60) {
    return "B"
  } else if (score > 50) {
    return "B-"
  } else if (score > 40) {
    return "C+"
  } else if (score > 30) {
    return "C"
  } else {
    return "C-"
  }
}
const getGradeColor = score => {
  if (score > 79) {
    return "green"
  } else if (score > 49) {
    return "yellow-2"
  } else {
    return "red"
  }
}

const getLocalTime = date => {
  if (!date) {
    return "N/A"
  }
  if (typeof date === "string" && date.length > 29) {
    date = date.substring(0, 29)
  }
  try {
    const localDate = new Date(
      new Date(date).getTime() - new Date().getTimezoneOffset() * 60000
    )
    return (
      localDate.getDate() +
      "/" +
      (localDate.getMonth() + 1) +
      "/" +
      localDate.getFullYear() +
      ", " +
      localDate.getHours() +
      ":" +
      localDate.getMinutes()
    )
  } catch (e) {
    return date
  }
}

const ScoringTable = ({ data, authed }) => {
  const { t } = useTranslation()
  const [isModalOpen, setModalOpen] = useState(false)

  let comparison = null

  if (data.ishigherthan > data.islowerthan && data.ishigherthan) {
    comparison = {
      value: data.ishigherthan,
      is: "higher",
    }
  } else if (data.ishigherthan < data.islowerthan && data.islowerthan) {
    comparison = {
      value: data.islowerthan,
      is: "lower",
    }
  }

  if (data.errorText) {
    return <ScoringError text={data.errorText} />
  }

  const recommendations = []
  data.result.vulns.forEach(item => {
    if (item.recommendation !== "") {
      recommendations.push({
        name: item.name,
        recommend: item.recommendation,
      })
    }
  })
  data.result.headers.forEach(item => {
    if (item.recommendation !== "") {
      recommendations.push({
        name: item.name,
        recommend: item.recommendation,
      })
    }
  })

  return (
    <section className="scoring-table">
      <h1 className="scoring-table__title">
        {t("Main information for")}
        <span className="wt-text wt-text--blue"> {data.object.url}</span>
      </h1>

      <div className="row">
        <div className="col-4 col-md-12">
          <div className="scoring-table__card scoring-table__card--vertical">
            <div>
              <h3>{t("Overall Security Grade")}</h3>
              <div className="wt-flex wt-flex-middle">
                <div
                  className={classNames(
                    "scoring-table__card-badge",
                    "scoring-table__card-badge--big",
                    `scoring-table__card-badge--${getGradeColor(
                      data.totalScore
                    )}`
                  )}
                >
                  {getScoreGrade(data.totalScore)}
                </div>
                <span
                  className={classNames(
                    "scoring-table__card-score",
                    "scoring-table__card-score--big",
                    "wt-text",
                    `wt-text--${getGradeColor(data.totalScore)}`
                  )}
                >
                  {typeof data.totalScore === "number" &&
                    Math.round(data.totalScore)}
                  %
                </span>
              </div>
            </div>
            {comparison && (
              <div className="wt-flex wt-flex-middle">
                <img
                  src={WarningIcon}
                  alt="warning"
                  className="wt-margin-right-10"
                />
                <span className="scoring-table__card-warning">
                  {t("Your security grade is")} {t(comparison.is)} {t("than")}{" "}
                  {comparison.value}% {t("of the companies in your industry.")}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="col-4 col-md-12">
          <div className="scoring-table__card">
            <div>
              <h3 className="wt-margin-bottom-30">{t("Site info")}</h3>
              <div className="wt-margin-bottom-30 wt-text wt-text--dark-gray">
                <span className="wt-text wt-text--bold wt-text--dark-gray">
                  {t("Tested on:")}{" "}
                </span>
                {getLocalTime(data.date)}
              </div>
              <div className="wt-margin-bottom-30 wt-text wt-text--dark-gray">
                <span className="wt-text wt-text--bold wt-text--dark-gray">
                  {t("Server IP:")}{" "}
                </span>
                {data.ip || "N/A"}
              </div>
              <div className="wt-margin-bottom-30 wt-text wt-text--dark-gray">
                <span className="wt-text wt-text--bold wt-text--dark-gray">
                  {t("Location:")}{" "}
                </span>
                {data.country || "N/A"}
              </div>
              <div className="wt-text wt-text--dark-gray">
                <span className="wt-text wt-text--bold wt-text--dark-gray">
                  {t("Web-site:")}{" "}
                </span>
                {data.object.url || "N/A"}
              </div>
            </div>
          </div>
        </div>
        <div className="col-4 col-md-12">
          <div className="scoring-table__card">
            <div>
              <h3 className="wt-margin-bottom-35">{t("Methodology")}</h3>
              <div className="wt-text wt-text--dark-gray wt-text--line-24 wt-margin-bottom-25">
                {t(
                  "We calculate points according to the parameters that are important for the protection of websites according to this standard"
                )}
              </div>
              <button
                className="wt-button wt-button--primary"
                onClick={() => setModalOpen(true)}
              >
                {t("About methodology")}
              </button>
            </div>
          </div>
        </div>
      </div>

      <h1 className="scoring-table__title">
        {t("Website Security and Compliance for")}
        <span className="wt-text wt-text--blue"> {data.object.url}</span>
      </h1>

      <div className="row">
        {data.result.vulns.map((item, index) => (
          <div key={index} className="col-4 col-md-6 col-sm-12">
            <div className="scoring-table__card scoring-table__card--horizontal">
              <div className="wt-flex-column wt-flex-center">
                <h3 className="scoring-table__card-title">{t(item.name)}</h3>
                <span
                  className={classNames(
                    "scoring-table__card-result",
                    "wt-text",
                    { "wt-text--red": item.score < 0 },
                    { "wt-text--green": item.score > 0 }
                  )}
                >
                  {t(item.result)}
                </span>
              </div>
              <div
                className="wt-flex-column wt-flex-middle wt-flex-center"
                style={{ width: 100 }}
              >
                <span
                  className={classNames(
                    "scoring-table__card-score",
                    "wt-text",
                    { "wt-text--red": item.score < 0 },
                    { "wt-text--green": item.score > 0 }
                  )}
                >
                  {item.score}
                </span>
                <div
                  className={classNames(
                    "scoring-table__card-badge",
                    {
                      "scoring-table__card-badge--red":
                        item.risk && item.risk.toUpperCase() === "HIGH",
                    },
                    {
                      "scoring-table__card-badge--yellow-2":
                        item.risk && item.risk.toUpperCase() === "MEDIUM",
                    }
                  )}
                >
                  {t(item.risk)}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <h1 className="scoring-table__title">
        {t("HTTP Security Headers and Content Security Policy Scoring for")}
        <span className="wt-text wt-text--blue"> {data.object.url}</span>
      </h1>

      <div className="row">
        {data.result.headers.map((item, index) => (
          <div key={index} className="col-4 col-md-6 col-sm-12">
            <div className="scoring-table__card scoring-table__card--horizontal">
              <div className="wt-flex-column wt-flex-center">
                <h3 className="scoring-table__card-title">{item.name}</h3>
                <span
                  className={classNames(
                    "scoring-table__card-result",
                    "wt-text",
                    { "wt-text--red": item.score < 0 },
                    { "wt-text--green": item.score > 0 }
                  )}
                >
                  {t(item.result)}
                </span>
              </div>
              <div
                className="wt-flex-column wt-flex-middle wt-flex-center"
                style={{ width: 100 }}
              >
                <span
                  className={classNames(
                    "scoring-table__card-score",
                    "wt-text",
                    { "wt-text--red": item.score < 0 },
                    { "wt-text--green": item.score > 0 }
                  )}
                >
                  {item.score}
                </span>
                <div
                  className={classNames(
                    "scoring-table__card-badge",
                    {
                      "scoring-table__card-badge--red":
                        item.risk && item.risk.toUpperCase() === "HIGH",
                    },
                    {
                      "scoring-table__card-badge--yellow-2":
                        item.risk && item.risk.toUpperCase() === "MEDIUM",
                    }
                  )}
                >
                  {t(item.risk)}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {data && data.leakedDataList && data.leakedDataList.length ? (
        <h1 className="scoring-table__title">
          {t("E-Mail leaks by scanning")}
          <span className="wt-text wt-text--blue"> {data.object.url}</span>
        </h1>
      ) : null}
      <div className="row scoring-table__leaked-data">
        {data &&
          data.leakedDataList &&
          data.leakedDataList.map((item, index) => (
            <div key={index} className="col-4 col-md-6 col-sm-12">
              <div className="scoring-table__card scoring-table__card--vertical">
                <div className="wt-flex wt-flex-middle wt-flex-between">
                  <span>{t("E-mail")}:</span>
                  <span className="wt-text wt-text--blue">{item.email}</span>
                </div>
                <div
                  className="wt-flex wt-flex-middle wt-flex-between"
                  style={{ padding: "10px 22px" }}
                >
                  <span>{t("Domain")}:</span>
                  {item.domain ? (
                    <span>
                      {item.domain === "<nil>"
                        ? "-"
                        : item.domain.substring(0, 20)}
                    </span>
                  ) : (
                    "-"
                  )}
                </div>
                <div
                  className="wt-flex wt-flex-middle wt-flex-between"
                  style={{ padding: "10px 22px" }}
                >
                  <span>{t("Passhash")}:</span>
                  {item.passHash ? (
                    <span>
                      {item.passHash === "<nil>"
                        ? "-"
                        : item.passHash.substring(0, 20)}
                    </span>
                  ) : (
                    "-"
                  )}
                </div>
                <div
                  className="wt-flex wt-flex-middle wt-flex-between"
                  style={{ padding: "10px 22px" }}
                >
                  <span>{t("PassSalt")}:</span>
                  {item.passSalt ? (
                    <span>
                      {item.passSalt === "<nil>"
                        ? "-"
                        : item.passSalt.substring(0, 20)}
                    </span>
                  ) : (
                    "-"
                  )}
                </div>
                <div
                  className="wt-flex wt-flex-middle wt-flex-between"
                  style={{ padding: "10px 22px" }}
                >
                  <span>{t("Password")}:</span>
                  {item.password ? (
                    <span>
                      {item.password === "<nil>"
                        ? "-"
                        : item.password.substring(0, 20)}
                    </span>
                  ) : (
                    "-"
                  )}
                </div>
              </div>
            </div>
          ))}
      </div>

      <h1 className="scoring-table__title scoring-table__title--blue">
        {t("Security recommendations")}
      </h1>

      <div className="row wt-margin-bottom-60">
        {recommendations.map((item, index) => (
          <div key={index} className="col-6 col-md-6 col-sm-12">
            <div className="scoring-table__card scoring-table__card--vertical">
              <div className="wt-flex-column wt-flex-center">
                <h3 className="scoring-table__card-title">{t(item.name)}</h3>
              </div>
              <div className="wt-flex">
                <div className="scoring-table__card-dot" />
                <span className="scoring-table__card-result">
                  {t(item.recommend)}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>

      <ReactModal
        isOpen={isModalOpen}
        style={customStyles}
        onRequestClose={() => setModalOpen(false)}
      >
        <Methodology onClose={() => setModalOpen(false)} />
      </ReactModal>
    </section>
  )
}

export default ScoringTable
